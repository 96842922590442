<template>
  <div class="worldVideo">
    <my-header></my-header>
    <div class="box text-left">
      <div class="djVideo"><video :src="body.videoUrl" controls></video></div>
      <div class="f22 c333 pt35 pl10">{{body.subject}}</div>
      <div class="f16 c666 pt20 pb30 pl10">上传于：{{body.createTime}}</div>
      <div class="conter f18 c333" v-html="body.description"></div>
    </div>
  </div>
</template>

<script>
  import myHeader from '../../components/head.vue';
  import timeFormat from '../../assets/js/timeFormat.js';
  export default{
    name: 'video',
    components:{
      myHeader
    },
    data(){
      return{
        id: '',
        body: ''
      }
    },
    mounted() {
      this.id = this.$route.query.id;
      this.getData();
    },
    methods:{
      async getData(){
        var params = {
          id: this.id
        }
        var res = await this.$api.get('/api/project_video/info', params);
        if(res.code == 0){
          res.data.createTime = timeFormat(res.data.createTime, 'yyyy-mm-dd')
          this.body = res.data;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .worldVideo{
    height: 100%;
    padding: 50px 40px;
    .box{
      overflow: auto;
      width: 100%;
      height: calc(100% - 50px);
      padding-right: 10px;
      .conter{
        line-height: 30px;
        background-color: #f7f7f7;
        padding: 40px 30px;
        border-radius: 30px;
      }
      .djVideo{
        width: 100%;
        height: 712px;
        background-color: #EEEEEE;
        text-align: center;
        line-height: 712px;
        font-size: 55px;
        video{
          width: 100%;
          height: 712px;
        }
      }
    }
  }
</style>
